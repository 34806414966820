var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v(_vm._s(_vm.msg || "Operazione in corso...")),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "section",
            {
              staticClass: "cont bg-image",
              style: {
                backgroundImage: `url(${require("@/assets/lisaweb.png/")})`,
              },
            },
            [
              _c("img", {
                staticClass: "left-logo",
                attrs: {
                  src: require("@/assets/lisaweb.png"),
                  alt: "Lisaweb 3",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                !_vm.isLoading
                  ? _c(
                      "div",
                      { staticClass: "login" },
                      [
                        _c("h3", [_vm._v("Credenziali di accesso utente")]),
                        _vm._v(" "),
                        _c("validation-observer", {
                          ref: "observer",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ invalid, handleSubmit }) {
                                  return [
                                    _c(
                                      "b-form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                            return handleSubmit(_vm.onSubmit)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "mt-5" },
                                          [
                                            _c("base-input", {
                                              attrs: {
                                                name: "Utente",
                                                label:
                                                  _vm.beForm[_vm.rep].username
                                                    .label,
                                                rules: _vm.getRules(
                                                  "username",
                                                  "auth"
                                                ),
                                                placeholder:
                                                  "Inserisci il nome utente",
                                              },
                                              model: {
                                                value: _vm.form.username,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "username",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.username",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("base-input", {
                                              attrs: {
                                                name: "Password",
                                                label:
                                                  _vm.beForm[_vm.rep].password
                                                    .label,
                                                type: "password",
                                                rules: _vm.getRules(
                                                  "password",
                                                  "auth"
                                                ),
                                                placeholder:
                                                  "Inserisci la password",
                                              },
                                              model: {
                                                value: _vm.form.password,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "password",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.password",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "justify-content": "flex-end",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      type: "submit",
                                                      variant: "lisaweb",
                                                      size: "sm",
                                                      disabled: invalid,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.beForm["auth"]
                                                          .submit.label
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3619944631
                          ),
                        }),
                        _vm._v(" "),
                        _c("Info"),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("Footer", { attrs: { version: _vm.version } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }